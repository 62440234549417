var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c(
        "div",
        {
          ref: "activator",
          staticClass: "action-base-button__activator top-status",
          on: {
            mousedown: _vm.onMouseDown,
            mouseenter: _vm.onMouseEnter,
            mouseleave: _vm.onMouseLeave
          }
        },
        [_vm._t("button")],
        2
      ),
      _vm._v(" "),
      _vm.isReadyToRender
        ? _c(
            "Dropdown",
            {
              attrs: {
                "parent-reference": _vm.$refs.activator,
                fixed: _vm.fixedContent,
                visible: _vm.isFocused
              },
              on: { "click-outside": _vm.onMouseDownOutside }
            },
            [
              _c(
                "List",
                _vm._l(_vm.options, function(option, index) {
                  return _c(
                    "ListElement",
                    {
                      key: index,
                      attrs: { size: _vm.smallSize, disabled: option.disabled },
                      nativeOn: {
                        mousedown: function($event) {
                          $event.preventDefault()
                          return function(event) {
                            return _vm.onSelectedValue(event, index)
                          }.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _vm._t(
                        "option",
                        function() {
                          return [
                            _vm.isOptionsValid
                              ? _c(
                                  "ListElementDescription",
                                  [
                                    _c("ListElementTitle", {
                                      attrs: {
                                        title: option,
                                        size: _vm.smallSize
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        },
                        { option: option }
                      )
                    ],
                    2
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }